









































@import "node_modules/element-ui/packages/theme-chalk/src/common/var.scss";
@import "@/assets/css/project-variables";

.menu-router-link{
  text-decoration: none;
  color: $--color-text-secondary;
}
